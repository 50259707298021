.lp__main {
}
.lp {
  min-height: 100vh;
}
.lp__section__one {
  min-height: 60vh;
  padding: 2.5% 20%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lp__section__one__start {
  width: 40%;
}
.lp__section__one__start > h1 {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #046307;
}
.lp__section__one__start > p {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 25px;
  color: #555;
}
.lp__section__one__start > button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: #046307;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.lp__section__one__start > button:hover {
  opacity: 0.9;
}
.lp__section__one__img {
  width: 50%;
}
.lp__section__two {
  min-height: 60vh;
  padding: 2.5% 20%;

  background-color: #fdf6c63d;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.lp__section__two__start {
  width: 40%;
}
.lp__section__two__start > h1 {
  font-size: 15px;
  font-weight: 500;
  color: #046307;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.lp__section__two__start__block {
  border-top: 1px solid #ddd;
  margin-bottom: 30px;
}
.lp__section__two__start__block > h1 {
  font-size: 15px;
  margin: 10px 0;
  color: #555;
  display: flex;
  align-items: center;
  gap: 10px;
}
.lp__section__two__start__block > p {
  width: 100%;
  line-height: 25px;
  font-size: 13px;
  margin: 10px 0;
  color: #555;
}
.lp__section__two__end {
  width: 50%;
  border-left: 1px solid #ddd;
  padding: 0 30px;
}
.lp__section__two__end > h1 {
  font-size: 15px;
  font-weight: 500;
  color: #046307;
  margin-bottom: 30px;
}
.lp__section__two__end__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
.lp__section__two__end__block__img {
  object-fit: cover;
  height: 200px;
  width: 350px;
}
.lp__section__two__end__block__text {
  margin-left: 30px;
}
.lp__section__two__end__block__text > p {
  width: 100%;
  line-height: 25px;
  font-size: 13px;
  margin: 10px 0;
  color: #555;
}
.lp__section__two__end__block__text > h3 {
  font-size: 14px;
  font-weight: 500;
  color: #046307;
  text-decoration: underline;
  padding-bottom: 10px;
  cursor: pointer;
}
.lp__section__three {
  min-height: 60vh;
  padding: 2.5% 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lp__section__three__start {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.lp__section__three__start > h3 {
  font-size: 15px;
  font-weight: 500;
  color: #046307;
}
.lp__section__three__start > h1 {
  width: 50%;
  font-size: 30px;
  color: #555;
  font-weight: 500;
}
.lp__faq {
  padding: 10px 0;
  border-top: 1px solid #046307;
}
.lp__faq__question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  color: #999;
}

.lp__faq__question > h1 {
  font-size: 13px;
  color: #999;
}
.lp__faq__question > span {
  display: flex;
  align-items: center;
  gap: 20px;
}
.lp__arrow {
  color: #046307;
  transition: transform 0.25s ease-out;
}
.lp__arrow.open {
  transform: rotateZ(90deg);
}
.lp__faq__answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 0;
}
.lp__faq__answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 0;
}
.lp__faq__answer > p {
  line-height: 25px;
  font-size: 13px;
  margin: 10px 0;
  color: #555;
}
.lp__faq__answer.open {
  max-height: 200px;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

@media all and (max-width: 768px) {
  .lp {
    padding: 0 5%;
  }
  .lp__section__one {
    min-height: 60vh;
    flex-direction: column;
    padding: 15% 0;
  }
  .lp__section__one__start {
    width: 100%;
  }
  .lp__section__one__start > h1 {
    font-size: 30px;
  }
  .lp__section__one__start > p {
    font-size: 14px;
  }
  .lp__section__one__start > button {
    margin-bottom: 30px;
  }
  .lp__section__one__img {
    width: 100%;
  }
  .lp__section__two {
    margin: 0 0;
    padding: 2.5% 5%;
    flex-direction: column;
    justify-content: start;
  }
  .lp__section__two__end {
    padding: 0;
  }
  .lp__section__two__end__block {
    flex-direction: column;
    justify-content: start;
    margin-bottom: 1px solid #ddd;
    margin-bottom: 30px;
  }
  .lp__section__two__end__block__img {
    object-fit: cover;
    height: 250px;
    width: 100%;
  }
  .lp__section__two__end__block__text {
    margin-left: 0;
  }
  .lp__section__two__start {
    width: 100%;
  }
  .lp__section__two__end {
    width: 100%;
    border: 0;
  }
  .lp__section__three {
    padding: 5% 5%;
  }
  .lp__section__three__start {
    flex-direction: column;
    justify-content: start;
    margin-bottom: 30px;
  }
  .lp__section__three__start > h3 {
    margin-bottom: 20px;
  }
  .lp__section__three__start > h1 {
    width: 100%;
    font-size: 25px;
    margin-bottom: 20px;
  }
}
