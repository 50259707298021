.navbar {
  box-shadow: 0.5px 0.5px 5px -1px lightgray;
  height: 7vh;
  background: white;
  width: 100%;
  margin: 0;
  padding: 0 18%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
}
.navbar_four_logo {
  object-fit: contain;
  height: 50px;
  margin: 0;
}
.navbar > button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: #046307;
  padding: 10px 15px;
  min-width: 100px;
  border-radius: 5px;
  cursor: pointer;
}
.navbar_ul {
  height: 100%;
  width: 60%;
  margin: 0 20%;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar_ul > li {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
}
.navbar_ul > li:hover {
  color: #046307;
}
.navbar_ul > li > h1 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}
.navbar_icon {
  font-size: 20px;
  margin-bottom: 10px;
}
#color1 {
  color: #046307;
}
#color2 {
  color: #046307;
}
#color3 {
  color: #046307;
}
#color4 {
  color: #046307;
}
#color5 {
  color: #046307;
}

@media all and (max-width: 600px) {
  .navbar {
    top: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navbar_ul {
    width: 100%;
    margin: 0 10px;
    padding: 0;
  }
  .navbar > button {
    display: none;
  }
  .navbar_four_logo {
    display: none;
  }
  .navbar_ul > li > h1 {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
  }
}
