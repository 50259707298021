.user__loan__body {
  min-height: 80vh;
  box-shadow: 0.5px 0.5px 5px -1px lightgray;
  background: white;
  width: 40%;
  margin: 0 30%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5% 0;
}
.user__loan__body > img {
  object-fit: contain;
  height: 100px;
  margin-bottom: 20px;
}

.user__loan__body > h1 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.user__loan__body > p {
  font-size: 13px;
  color: #555;
  margin-bottom: 20px;
}
.user__loan__body > button {
  margin-top: 50px;
  min-width: 200px;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: black;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.user__loan__body__info {
  display: flex;
  align-items: center;
  width: 65%;
  margin: 20px 0;
  border: 1px solid #0463077a;
  background-color: rgba(144, 238, 144, 0.288);
  padding: 15px 15px;
  border-radius: 10px;
}
.user__loan__body__info > span {
}
.user__loan__body__info > span > p {
  font-size: 13px;
  color: #555;
  margin-bottom: 10px;
}
.user__loan__body__info > span > h3 {
  font-size: 14px;
  font-weight: 600;
  color: #046307;
  text-decoration: underline;
  cursor: pointer;
}
.user__loan__body__info__icon {
  margin-left: auto;
  color: goldenrod;
  font-size: 20px;
}

@media all and (max-width: 768px) {
  .user__loan__body {
    min-height: 100vh;
    background: white;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
  .user__loan__body__info {
    width: 90%;
  }
  .user__loan__body > button {
    margin-top: 30px;
    min-width: 90%;
  }
}
