.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-left: 20%;
  margin-right: 20%;
  border-bottom: 1px solid #046307;
  color: #046307;
}

.header__logo {
  margin-right: auto;
  object-fit: contain;
  height: 50px;
  cursor: pointer;
}

.header__nav {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 20px;
}
.header__nav > li {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.header__acc {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__acc > button {
  font-size: 14px;
  font-weight: 600;
  border: 0;
  color: #046307;
  background-color: transparent;
  cursor: pointer;
}
.header__acc > button:last-of-type {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: #046307;
  padding: 10px 15px;
  border-radius: 5px;
}

.header__menu {
  font-size: 20px;
  cursor: pointer;
}
.sidebar {
  position: fixed;
  z-index: 10;
  top: -100%;
  right: 0;
  background-color: #046307;
  color: white;
  width: 100%;
  min-height: 50vh;
  padding: 20px calc(20%);
  transition: all 1s ease-in-out;
}
.sidebar--active {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  background-color: #046307;
  color: white;
  width: 100%;
  min-height: 50vh;
  padding: 20px calc(20%);
  transition: all 1s ease-in-out;
}
.sidebar__header {
  width: 100%;
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid white;
}
.sidebar__header > button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: transparent;
  padding: 10px 15px;
  border-radius: 5px;
  margin-left: auto;
  cursor: pointer;
}
.sidebar__nav {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 20px;
  margin: 20px 0;
}
.sidebar__nav > li {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.sidebar__acc {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sidebar__acc > button {
  font-size: 14px;
  font-weight: 600;
  border: 0;
  color: white;
  background-color: transparent;
  cursor: pointer;
}
.sidebar__acc > button:last-of-type {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: #046307;
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
}

@media all and (max-width: 768px) {
  .header {
    padding: 2.5% 0%;
    margin: 0;
  }
  .header__nav {
    display: none;
  }
  .sidebar--active {
    min-height: 50vh;
    padding: 5% 5%;
  }
}
