.toaster {
  position: fixed;
  opacity: 1;
  width: 20%;
  top: 2.5vh;
  right: 40%;
  padding: 15px 15px;
  border-radius: 1.5px;
  transition: all 1s ease-in-out;
  z-index: 100;
  transform: translateX(0);
  box-shadow: 0.5px 0.5px 1.5px -1px #28282b;
}
.toaster.slide {
  transform: translateX(200%);
  opacity: 1;
  display: block;
}
.toaster_body {
  display: flex;
  align-items: center;
}
.toaster_body > .toaster_close {
  margin-left: auto;
  cursor: pointer;
  font-size: 17.5px;
  color: rgb(167, 22, 22);
}
.toaster_body > p {
  color: rgb(167, 22, 22);
  font-size: 13px;
  width: 80%;
}
@media all and (max-width: 768px) {
  .toaster {
    position: fixed;
    opacity: 1;
    width: 90%;
    top: 2.5vh;
    left: 5%;
    right: 0;
    padding: 15px 15px;
    transform: translateX(0);
  }
  .toaster.slide {
    transform: translateX(200%);
    opacity: 1;
    display: block;
  }
}
