.user__profile__body {
  min-height: 80vh;
  box-shadow: 0.5px 0.5px 5px -1px lightgray;
  background: white;
  width: 40%;
  margin: 0 30%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5% 0;
}
.user__profile__img {
  object-fit: cover;
  height: 100px;
  width: 100px;
  border-radius: 100%;
}

@media all and (max-width: 768px) {
  .user__profile__body {
    min-height: 100vh;
    background: white;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
}
